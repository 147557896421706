@import url('https://fonts.googleapis.com/css2?family=Zen+Old+Mincho&display=swap');

body {
  background: #000;
}

.layer {
  width: 1000px;
  height: 700px;
  background: url(assets/img/layer.png) no-repeat center center/contain;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  pointer-events: none;
}

.bg {
  position: relative;
  width: 1000px;
  height: 700px;
  background: url(assets/img/bg.jpg) no-repeat center center/contain;
  margin: 0 auto;
  box-shadow: inset 0 0 10px 15px #000
}

.video-container {
  transform: scale(.55);
  position: absolute;
  left: 430px;
  top: 182px;
  width: 380px;
  height: 307px;
  -webkit-mask-image: url(assets/img/mask.png);
  mask-image: url(assets/img/mask.png);
  opacity: 0;
  transition: opacity .5s;
}

.video-container.show {
  opacity: 1;
}

.video-container::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  background-image: radial-gradient(#111 30%, transparent 31%), radial-gradient(#111 30%, transparent 31%);
  background-size: 4px 4px;
  background-position: 0 0, 2px 2px;
  pointer-events: none;
}

.video-container iframe {
  width: 430px;
  height: 347px;
  transform: skew(0deg, -9deg) translateY(-30px);
}

.news-mask {
  position: absolute;
  top: 380px;
  left: 500px;
  width: 250px;
  height: 200px;
  overflow: hidden;
  mask: linear-gradient(to right, transparent 0%, white 5%, white 95%, transparent 100%);
}

.news-container {
  display: inline-block;
  white-space: nowrap;
  font-weight: 700;
}

.news-description {
  transform: skewX(-10deg) rotateZ(-8deg);
  transform-origin: left bottom;
}

.news-description a {
  color: #cacaca;
  text-decoration: none;
}

.button-pause,
.button-play {
  width: 40px;
  height: 40px;
  appearance: none;
  border: none;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
  margin-right: 25px;
}

.button-pause {
  background: no-repeat center/100% url(assets/img/pause.svg);
}

.button-play {
  background: no-repeat center/100% url(assets/img/play.svg);
}

.button-settings {
  width: 36px;
  height: 36px;
  appearance: none;
  border: none;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
  background: no-repeat center/100% url(assets/img/gear.svg);
  position: absolute;
  bottom: -46px;
  right: 20px;
}

input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  background: #fff;
  height: 1px;
  cursor: pointer;
  margin-left: 10px;
}

/* Track: Chrome, Safari, Opera, Edge Chromium */
input[type="range"]::-webkit-slider-runnable-track {
  background: #fff;
  height: 1px;
}

/* Thumb: Chrome, Safari, Opera, Edge Chromium */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 20px;
  width: 20px;
  margin-top: -9px;
  /* 位置の調整が必要 */
  background-color: #000;
  border-radius: 50%;
  border: 1px solid #fff;
}

/* Track: Firefox */
input[type="range"]::-moz-range-track {
  background: #fff;
  height: 1px;
}

/* Thumb: Firefox */
input[type="range"]::-moz-range-thumb {
  border: none;
  height: 20px;
  width: 20px;
  background-color: #000;
  border-radius: 50%;
  border: 1px solid #fff;
}

.video-controller {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: -50px;
  right: 80px;
}

.icon-volume {
  width: 30px;
  height: 30px;
  appearance: none;
  border: none;
  cursor: pointer;
}

.icon-volume.-mute {
  background: no-repeat center/100% url(assets/img/mute-volume.svg);
}

.icon-volume.-low {
  background: no-repeat center/100% url(assets/img/low-volume.svg);
}

.icon-volume.-mid {
  background: no-repeat center/100% url(assets/img/mid-volume.svg);
}

.icon-volume.-high {
  background: no-repeat center/100% url(assets/img/high-volume.svg);
}

.app-title {
  color: #fff;
  font-family: 'Zen Old Mincho', serif;
  font-weight: 400;
  position: absolute;
  bottom: -75px;
  left: 20px;
  font-size: 30px;
  display: flex;
  align-items: center;
}

.app-title span {
  letter-spacing: -.1em;
}

.icon-info {
  appearance: none;
  border: none;
  width: 20px;
  height: 20px;
  background: no-repeat center/100% url(assets/img/info-circle.svg);
  margin: 5px 0 0 15px;
  cursor: pointer;
}

.modal {
  background: #000;
  position: fixed;
  top: 0;
  left: -400px;
  width: 400px;
  height: 75%;
  z-index: 100;
  transition: left .2s ease-out;
  color: #fff;
  transition: left .2s linear, opacity .2s linear;
  opacity: 0;
}

.modal.show {
  left: 0;
  opacity: 1;
}

.modal-content {
  padding: 20px;
}

.button-close {
  appearance: none;
  background: #000;
  border: 1px solid #fff;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  display: block;
  position: relative;
  width: 40px;
  height: 40px;
  position: absolute;
  left: 0;
  right: 0;
  top: 250px;
  margin: auto;
}

.button-close::before,
.button-close::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1px;
  /* 棒の幅（太さ） */
  height: 22px;
  /* 棒の高さ */
  background: #fff;
  /* バツ印の色 */
}

.button-close::before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.button-close::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.modal-radio-label {
  display: block;
  margin-bottom: 15px;
  font-size: 15px;
  cursor: pointer;
}

.modal-radio {
  margin-right: 8px;
  position: relative;
  top: 1px;
}

.youtube-url-label {
  font-size: 13px;
  margin-left: 20px;
}

.form-container {
  display: flex;
  align-items: center;
}

.youtube-url-input {
  padding: 4px;
  margin-left: 20px;
  width: 260px;
  font-size: 16px;
  min-height: 32px;
  box-sizing: border-box;
  border: none;
  border-radius: 2px;
}

.button-submit {
  appearance: none;
  background: none;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 2px;
  cursor: pointer;
  padding: 2px 12px;
  margin-left: 6px;
  min-height: 32px;
}

.info-modal {
  visibility: hidden;
  opacity: 0;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  color: #fff;
  transition: visibility .25s, opacity .25s;
  font-family: 'Zen Old Mincho', serif;
  letter-spacing: 0.1em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-modal.show {
  visibility: visible;
  opacity: 1;
}

.info-modal h2 {
  font-size: 18px;
  margin-bottom: 20px;
}

.info-modal p {
  font-size: 15px;
  margin: 0 0 10px;
  line-height: 1.5;
}

.loading-screen {
  background: #000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  transition: opacity 1s;
}

.loading-screen.fade-out {
  opacity: 0;
  pointer-events: none;
}

@media (max-width: 999px) {
  body {
    overflow: hidden;
  }
  .container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
  .bg {
    position: absolute;
  }
  .video-controller {
    width: 100vw;
    position: fixed;
    top: calc(74% + 65px);
    left: 15px;
    bottom: auto;
  }
  .button-settings {
    position: fixed;
    top: calc(74% + 65px);
    right: 15px;
  }
  .app-title {
    position: fixed;
    top: 74%;
    left: 15px;
    bottom: auto;
    font-size: 24px;
  }
  .modal {
    width: 100%;
    height: 250px;
    top: 100%;
    left: 0;
    transition: top .2s linear, opacity .2s linear;
    opacity: 0;
  }
  .modal.show {
    top: calc(74% - 200px);
    opacity: 1;
  }
  .modal-content {
    padding: 20px 15px;
  }
  .button-close {
    top: 190px;
  }


}
